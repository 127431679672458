
export default {
  name: 'AssistenteTecnologicoOption',
  props: ['isNatedeSmart'],
  data() {
    return {
      optionalServiceToggle: false
    }
  },
  computed: {
    optionalService(){
      return this.$store.getters.product('NT-INST002')
    }
  },
  methods: {
    emitChange(){
      this.$emit('toggleOptionalService', this.optionalServiceToggle ? this.optionalService : false)
    }
  }
}
